import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserStore } from '../user/user.store';
import { catchError, delay, from, switchMap, throwError } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';

export const cognitoInterceptor: HttpInterceptorFn = (req, next) => {
  const user = inject(UserStore);
  const headers = req.headers
    .set('Authorization', `Bearer ${user.token()}`)
    .set('Content-Type', 'application/json');
  const authReq = req.clone({ headers });
  return next(authReq).pipe(
    catchError((err) => {
      console.log(err);
      if (isCORSOr401Error(err)) {
        return from(fetchAuthSession()).pipe(
          delay(1000),
          switchMap((session) => {
            const updatedReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${session.tokens?.idToken}`,
              },
            });
            return next(updatedReq);
          }),
          catchError((innerErr) => {
            throw new Error(innerErr);
          })
        );
      }
      throw Error(err);
    })
  );
};

function isCORSOr401Error(error: HttpErrorResponse): boolean {
  return error.status === 0 || error.status === 401;
}
