import { Component, Input } from '@angular/core';
import { Address } from '@shared';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressComponent {
  @Input() address!: Address;
}
