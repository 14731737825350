import { ErrorHandler } from '@angular/core';

export class CustomErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    console.log(error.message, error.name);
    console.error(error);
    switch (error.name) {
      case 'UserUnAuthenticatedException':
        console.log(window.location);
        if (
          window.location.pathname != '/auth' &&
          window.location.pathname != '/auth/sign-up' &&
          window.location.pathname != '/auth/psw-reset' &&
          window.location.search != '?scope=signup'
        )
          window.location.href = 'auth';
        break;
    }
  }
}
