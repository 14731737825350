import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, input, output } from '@angular/core';

@Component({
  selector: 'app-bottom-sheet',
  standalone: true,
  imports: [NgClass],
  templateUrl: './bottom-sheet.component.html',
})
export class BottomSheetComponent {
  visible = input.required<boolean>()
  changeVis = output<boolean>()

  constructor(){
  }

  public toggle(): void {
    this.changeVis.emit(false);
  }
}
