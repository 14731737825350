<div class="p-4 flex justify-between">
  <div class="space-y-2">
    <h3 class="text-base font-semibold">
      Schritt {{ currentStep() }}: {{ title() }}
    </h3>
    <div class="flex max-w-xs space-x-3">
      @for(step of stepsArr; track step; let index = $index){<span
        class="w-12 h-2 rounded-full"
        [ngClass]="{
          'bg-primary-600': currentStep() > index + 1,
          'bg-gray-800 ': currentStep() === index + 1,
          'bg-gray-400': currentStep() < index + 1
        }"
      ></span
      >}
    </div>
  </div>
  <button class="btn btn-circle btn-ghost" (click)="onInfoEmit()">
    <ng-icon name="heroInformationCircle" size="2em" />
  </button>
</div>
