@if(product){
<div
  class="w-full bg-base-100 flex p-2 flex hover:bg-primary-100 hover:bg-primary-200 cursor-pointer"
  (click)="onDetails()"
>
  <!-- <div class="flex w-full px-4 py-6 justify-between"  (click)="onDetails()"> -->
  <!-- <div
  class="flex flex-row border-1 border-gray-200 shadow-lg rounded-lg cursor-pointer bg-default-50 min-h-16"
  
> -->
  <div class="flex flex-col justify-center">
    @if(product.imageUrl && showPic()){
    <img
      [src]="product.imageUrl"
      alt=""
      class="w-16 h-16 object-cover rounded-lg"
    />
    }@else if(showPic()) {

    <img
      src="assets/logo_Kibazar_square.png"
      alt=""
      class="w-16 h-16 object-cover rounded-lg grayscale pl-1 opacity-25"
    />

    }
  </div>
  <div class="flex flex-col p-2">
    <h3 class="text-primary-900 font-semibold">{{ product.name }}</h3>
    <p class="text-default-600 text-sm space-x-2">
      <span class="badge badge-outline badge-sm opacity-70">{{
        product.articleNr
      }}</span>
      @if(product.size){
      <span class="badge badge-outline opacity-50 badge-sm">{{
        product.size
      }}</span>
      } @if(product.color){
      <span class="badge badge-outline opacity-50 badge-sm">{{
        product.color
      }}</span>
      }
    </p>
  </div>
  <div class="flex-1"></div>
  <div class="flex flex-col justify-between items-end p-2">
    <div>
      @if(product.bazaarId && (!product.invoiceId || product.invoiceId ===
      null)){
      <p class="badge badge-success badge-outline">aktiv</p>
      } @if(product.invoiceId && product.invoiceId != null){
      <p class="badge">verkauft</p>
      }
    </div>
    <p class="text-default-800">
      {{ product.price | currency : "EUR" }}
    </p>
  </div>
</div>
} @else {
<p>no data</p>
}
