import { inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { BazaarUser } from '@shared';
import { UserService } from './user.service';
import { Hub } from 'aws-amplify/utils';
import chalk from 'chalk';
import { withDevtools } from '@angular-architects/ngrx-toolkit';

type UserState = {
  user: BazaarUser | null;
  loading: boolean;
  signedIn: boolean;
  token: string | null;
  organizations: string[];
};

const initialState: UserState = {
  user: null,
  loading: false,
  signedIn: false,
  token: null,
  organizations: [],
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('User'),
  withState(initialState),
  withMethods((state) => {
    const userService = inject(UserService);
    return {
      async login(userName: string, password: string): Promise<void> {
        patchState(state, { loading: true });
        const signedInOutput = await userService.login(userName, password);
        if (signedInOutput)
          patchState(state, {
            loading: false,
            signedIn: signedInOutput.isSignedIn,
          });
      },
      async logout() {
        patchState(state, { loading: true });
        await userService.logout();
        patchState(state, { loading: false });
        window.location.reload();
      },
      async getToken() {
        const token = await userService.getToken().then((token) => {
          const organizations = token?.payload['cognito:groups'] as string[];
          patchState(state, {
            token: token?.toString(),
            organizations,
          });
          return token?.toString();
        });
        const user = await userService.getBackendUser();
        patchState(state, { user });
      },
      async getUser() {
        patchState(state, { loading: true });
        const user = await userService.getBackendUser();
        patchState(state, { loading: false, user });
      },
      async setUser(user: BazaarUser) {
        patchState(state, { user });
      },
    };
  }),
  withHooks({
    onInit(state) {
      const userService = inject(UserService);
      const listener = async (data: any) => {
        switch (data.payload.event) {
          case 'signedIn':
            await userService
              .getToken()
              .then((token) =>
                patchState(state, { token: token?.toString(), signedIn: true })
              );
            console.log('user signed in');
            break;
          case 'signIn_failure':
            console.log('user sign in failed');
            break;
          case 'signUp':
            userService
              .getToken()
              .then((token) => patchState(state, { token: token?.toString() }));
            console.log('user signed up');
            break;
          case 'signUp_failure':
            console.log('user sign up failed');
            break;
          case 'signedOut':
            console.log('user have been signedOut successfully.');
            break;
          case 'autoSignIn':
            userService
              .getToken()
              .then((token) => patchState(state, { token: token?.toString() }));
            console.log('auto sign in successful');
            break;
          case 'autoSignIn_failure':
            console.log('auto sign in failed');
            break;
          case 'tokenRefresh':
            console.log('auth tokens have been refreshed.');

            window.location.reload();

            break;
          case 'tokenRefresh_failure':
            console.log('failure while refreshing auth tokens.');
            break;
          case 'signInWithRedirect':
            console.log(
              'signInWithRedirect API has successfully been resolved.'
            );
            break;
          case 'signInWithRedirect_failure':
            console.log(
              'failure while trying to resolve signInWithRedirect API.'
            );
            break;
          case 'customOAuthState':
            console.log('custom state returned from CognitoHosted UI');
            break;
        }
      };
      Hub.listen('auth', listener);
      userService.getAuthUser().then((user) => {
        patchState(state, {
          loading: false,
          signedIn: user.signInDetails?.loginId != null,
        });
      });
      userService.getToken().then((token) => {
        const organizations = token?.payload['cognito:groups'] as string[];
       
        patchState(state, {
          token: token?.toString(),
          organizations,
        });
      });
    },
    onDestroy(store) {
      console.log('count on destroy');
    },
  })
);
