import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-primary',
  standalone: true,
  imports: [],
  template: `<button class="px-4 py-1 rounded bg-primary-500 text-primary-100 h-12" (click)="onClick()">
    <p>
      <ng-content></ng-content>
    </p>
  </button>`,
})
export class PrimaryButtonComponent {
  @Output() clicked = new EventEmitter<void>();
  onClick() {
    this.clicked.emit();
  }
}
