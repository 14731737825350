import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroCalendarDaysSolid, heroHomeSolid } from '@ng-icons/heroicons/solid';

@Component({
  selector: 'app-bottom-navbar',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgIcon],
  viewProviders: [provideIcons({ heroHomeSolid, heroCalendarDaysSolid })],
  templateUrl: './bottom-navbar.component.html',
  styles: `
  :host{@apply fixed bottom-0 w-full flex flex-row items-start border-t-2 border-default-100 py-2 bg-primary-50}; 
  .nav-btn{@apply flex flex-col basis-1/4 items-center}`,
})
export class BottomNavbarComponent {}
