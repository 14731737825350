@if(authService.signedIn()){
<app-header
  (clicked)="onToggleDrawer()"
  class="print:hidden"
  [user]="authService.user()"
></app-header>
<div class="flex-1 overflow-y-auto print:hidden">
  <router-outlet></router-outlet>
</div>
<router-outlet name="print"></router-outlet>
<app-bottom-navbar class="print:hidden"></app-bottom-navbar>

<app-drawer
  class="bg-neutral-50 w-full md:w-96 absolute inset-y-0 left-0 transform transition duration-300 ease-in-out z-20 flex flex-col print:hidden"
  [ngClass]="{ '-translate-x-full': !showDrawer }"
  (closeSideNav)="onToggleDrawer()"
>
  <div class="flex justify-between px-4">
    <img src="assets/logo_Kibazar.png" alt="" class="w-48" />
    <button (click)="onToggleDrawer()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
  <app-nav [routes]="navItems" (activatedRoute)="onToggleDrawer()"></app-nav>
  <div class="w-full p-2 justify-center flex">
    <a class="btn" href="https://buymeacoffee.com/mukibasar" target="_blank"
      >☕️ Einen Kaffee spenden</a
    >
  </div>

  <button (click)="onLogout()" class="p-4">Logout</button>
</app-drawer>
}@else{
<div class="flex h-full items-center justify-center">
  <app-spinner></app-spinner>
</div>
}
<dialog id="bottom_nav_modal" class="modal modal-bottom" #menu>
  <div class="modal-box">
    <app-nav [routes]="navItems" (activatedRoute)="onMenuItem(menu)"></app-nav>
  </div>
  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>
