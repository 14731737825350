import { Component, input, output } from '@angular/core';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroInformationCircle } from '@ng-icons/heroicons/outline';
import { createNumberArray } from '../../core/util';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [NgClass,NgIcon],
  viewProviders: [provideIcons({ heroInformationCircle })],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  steps = input<number>(0);
  currentStep = input<number>(0);
  title = input<string>();
  onInfo = output<void>();
  stepsArr!: number[];

  ngOnInit() {
    this.stepsArr = createNumberArray(this.steps() ?? 0);
  }

  onInfoEmit(){
    this.onInfo.emit()
  }
}
