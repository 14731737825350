import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  template: `<button
    class="px-4 py-1 rounded bg-default-800 text-default-50 disabled:bg-default-200 disabled:text-default-500 h-12"
    [disabled]="disabled"
    (click)="onClick()"
  >
    <p>
      <ng-content></ng-content>
    </p>
  </button>`,
})
export class ButtonComponent {
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<void>();
  onClick() {
    this.clicked.emit();
  }
}
