import {
  Component,
  ElementRef,
  inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { DrawerComponent } from '../../ui/drawer/drawer.component';
import { HeaderComponent } from '../../ui/header/header.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { SpinnerComponent } from '@ui';
import { UserStore } from '../../core/user/user.store';
import { BottomNavbarComponent } from '../../ui/bottom-navbar/bottom-navbar.component';
import { NavComponent } from './nav/nav.component';

@Component({
  selector: 'app-default-layout',
  standalone: true,
  imports: [
    BottomNavbarComponent,
    HeaderComponent,
    NavComponent,
    SpinnerComponent,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    DrawerComponent,
    NgClass,
    AsyncPipe,
  ],
  templateUrl: './default-layout.component.html',
  styles: `:host{@apply flex flex-col h-screen}`,
})
export class DefaultLayoutComponent {
  authService = inject(UserStore);
  private router = inject(Router);
  showDrawer!: boolean;
  @ViewChild('bottom_nav_modal') menu!: TemplateRef<ElementRef>;

  ngOnInit() {
    // if(!this.authService.user()?.sellerId) this.getUser()
    this.getUser();
  }

  async getUser() {
    return await this.authService.getUser();
  }

  navItems: { id: number; path: string; label: string }[] = [
    { id: 1, path: 'dashboard', label: 'Startseite' },
    { id: 2, path: 'organizations', label: 'Organisationen' },
    { id: 3, path: 'bazaars', label: 'Basare' },
    { id: 4, path: 'articles', label: 'Ablage' },
    { id: 5, path: 'cash-station-list', label: 'Kassen' },
    // { id: 6, path: 'cashier/1/1', label: 'testKasse' },
  ];

  onToggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }
  onMenuItem(el: HTMLDialogElement) {
    el.close();
  }
  onLogout() {
    this.authService.logout();
    this.onToggleDrawer();
  }
}
