import { ApplicationConfig, InjectionToken } from '@angular/core';
import { routes } from './app.routes';
import { provideCore } from './core/core';

export const createAppConfig = (
  config: EnvironmentConfiguration
): ApplicationConfig => {
  return {
    providers: [
      provideCore({ routes }),
      { provide: ENVIRONMENT_CONFIG, useValue: config },
    ],
  };
};

export type EnvironmentConfiguration = {
  apiEndpoint: string;
  receiptApi: string;
  userPoolId: string;
  userPoolClientId: string;
};

export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfiguration>(
  'Environment_Configuration'
);
