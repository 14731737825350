import { Component, inject, input, output } from '@angular/core';
import {
  RouterLink,
  RouterOutlet,
  RouterLinkActive,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [RouterLink, RouterOutlet, RouterLinkActive],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
})
export class NavComponent {
  private router = inject(Router);
  routes = input<{ id: number; path: string; label: string }[]>();
  activatedRoute = output<void>();
  onCashier() {
    this.router.navigate(['cashier', '1', '1']);
    this.activatedRoute.emit();
  }

  onOrganizations() {
    this.router.navigate(['organizations', 'my']);
    this.activatedRoute.emit();
  }

  onItem() {
    this.activatedRoute.emit();
  }
}
