import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BazaarUser } from '@shared';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  signOut,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { delay, lastValueFrom, map, retry } from 'rxjs';
import { ENVIRONMENT_CONFIG } from '../../app.config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  environment = inject(ENVIRONMENT_CONFIG);
  backendUrl = this.environment.apiEndpoint;
  router = inject(Router);

  constructor(private http: HttpClient) {
    const existingConfig = Amplify.getConfig();
    this._adjustConfig(existingConfig);
  }

  async getAuthUser() {
    return await getCurrentUser();
  }

  private _adjustConfig(existingConfig: ResourcesConfig): void {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: this.environment.userPoolId,
          userPoolClientId: this.environment.userPoolClientId,
          signUpVerificationMethod: 'code',
        },
      },
      API: {
        ...existingConfig,
        REST: {
          ...existingConfig.API?.REST,
          mukibasarApi: {
            endpoint: this.environment.apiEndpoint,
          },
        },
      },
    });
  }

  /**
   *
   * @param username
   * @param password
   * @returns
   */
  login(username: string, password: string) {
    return signIn({ username, password });
  }

  logout() {
    signOut();
    this.router.navigate(['/auth']);
  }

  async getToken() {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken;
  }

  async getUserAttr() {
    return await fetchUserAttributes();
  }

  async updateUserAttr() {
    await updateUserAttributes({
      userAttributes: {
        'custom:isAdmin': 'true',
        name: 'Jon Doe',
        'custom:sellerId': '123',
        'custom:id': '123',
      },
    });
  }

  async getBackendUser(): Promise<BazaarUser> {
    const request$ = this.http.get<BazaarUser>(`${this.backendUrl}/user`).pipe(
      map((response) => {
        if (response === null) throw new Error();
        return response;
      }),
      retry({ count: 2, delay: 1000 })
    );
    return (await lastValueFrom<BazaarUser>(request$)) ?? [];
  }
}
