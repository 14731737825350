import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';

export const routes: Routes = [
  /* Refactored routes */
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./feature/dashboard/dashboard.routes'),
      },
      {
        path: 'bazaars',
        loadChildren: () => import('./feature/bazaar/bazaar.routes'),
      },
      {
        path: 'check-out',
        loadChildren: () => import('./feature/check-out/check-out.routes'),
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('./feature/organization/organization.routes'),
      },
      {
        path:'articles',
        loadChildren: () => import('./feature/articles/article.routes'),
      },
      {
        path: 'cash-station-list',
        loadChildren: () =>
          import('./feature/cash-station/cash-station.routes'),
      },
      {
        path: 'print',
        outlet: 'print',
        loadChildren: () => import('./feature/print/print.routes'),
      },
      {
        path: 'user',
        loadChildren: () => import('./feature/user/user.routes'),
      },
    ],
  },
  /* old Routes */

  {
    path: 'auth',
    loadChildren: () => import('./feature/auth/sign-in-up.routes'),
  },
];
