import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { Article } from '@shared';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CurrencyPipe],
  templateUrl: './product-card.component.html',
  styles: `:host{@apply w-full even:bg-default-100}`,
})
export class ProductCardComponent {
  @Input() product!: Article;
  @Output() detailsEvent = new EventEmitter<void>();
  showPic = input<boolean>(true);

  onDetails() {
    this.detailsEvent.emit();
  }
}
