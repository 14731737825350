<div
  class="bg-default-500 opacity-75 fixed inset-0 transform transition-opacity duration-200 ease-in-out z-20"
  [ngClass]="{ 'translate-x-full': !visible() }"
  (click)="toggle()"
></div>

<div
  class="absolute inset-x-0 bottom-0 transform transition duration-200 ease-in-out z-20 w-full xl:max-w-2xl md:container md:mx-auto max-h-[90%]"
  [ngClass]="{ 'translate-y-full': !visible() }"
>
  <div class="px-8 py-2 bg-default-50 rounded-t-xl">
    <hr class="mx-auto h-1 w-40 border-0 rounded-full bg-primary-400" />
    <ng-content></ng-content>
  </div>
</div>
