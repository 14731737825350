import { v4 as uuid, v1 as uuidV1, validate } from 'uuid';

/**
 * Checks if an id is a valid uuid
 * @param id
 * @returns boolean
 */
export function isUuid(id: string): boolean {
  return validate(id);
}

/**
 * Creates an Array with n numbers
 * @param n
 * @returns
 */
export function createNumberArray(n: number): number[] {
  return Array.from({ length: n }, (_, i) => i + 1);
}

/**
 * validates if input is an ArticleNr
 * @param articleNr
 * @returns true if valid
 */
export function validateArticleNr(articleNr: string): boolean {
  const regex = /^[0-9]{3}-[0-9]+-[0-9]+$/;
  return regex.test(articleNr);
}


export function isValidULID(ulid: string): boolean {
  const ulidRegex = /^[0-9A-HJKMNP-TV-Z]{10}[0-9A-HJKMNP-TV-Z]{16}$/;
  return ulidRegex.test(ulid);
}

/**
 *
 * @param current
 * @param scanned
 */
export function isSame(current: string, scanned: string): boolean;
export function isSame(current: number, scanned: number): boolean;
export function isSame(current: any, scanned: any) {
  return current === scanned;
}
/**
 *
 * @param current
 * @param scanned
 */
export function isDifferent(current: string, scanned: string): boolean;
export function isDifferent(current: number, scanned: number): boolean;
export function isDifferent(current: any, scanned: any) {
  return current !== scanned;
}
