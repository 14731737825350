<h3 class="flex w-full text-lg font-semibold">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    class="w-5 h-5 text-default-700 inline-block mt-0.5"
  >
    <path
      fill-rule="evenodd"
      d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
      clip-rule="evenodd"
    />
  </svg>
  <span class="h-full inline-block align-middle text-default-800">
    Anschrift:
  </span>
</h3>
<section class="rounded-lg bg-default-50 p-4">
  <p class="font-bold text-primary-900">{{ address.building }}</p>
  <p>{{ address.street }}</p>
  <p>{{ address.postalCode }} {{ address.city }}</p>
</section>
