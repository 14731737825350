import { AsyncPipe, SlicePipe } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BazaarUser } from '@shared';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, AsyncPipe, SlicePipe],
  templateUrl: './header.component.html',
  styles: ``,
})
export class HeaderComponent {
  @Output() clicked = new EventEmitter<void>();
  user = input<BazaarUser | null>()
  constructor() {}

  onMenu() {
    this.clicked.emit();
  }

  onRefresh(){
    window.location.reload()
  }
}
