import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pill',
  standalone: true,
  imports: [],
  template: `<p
    class="bg-default-50 text-primary-800 border-primary-800 border px-2 py-1 rounded text-sm text-center"
  >
    {{ text }}
  </p>`,
})
export class PillComponent {
  @Input() text!: string | null;
}
