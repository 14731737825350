<nav role="navigation">
  <ul class="divide-y divide-primary-200" (click)="onItem()">
    @for (item of routes(); track item.id) {
    <li
      class="flex h-16 px-4 hover:bg-primary-50 focus:bg-primary-100 cursor-pointer"
    >
      <a [routerLink]="item.path" class="w-full h-full flex items-center">{{
        item.label
      }}</a>
    </li>
    }
  </ul>

  <div class="flex-1"></div>
</nav>
